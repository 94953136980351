import { graphql } from 'gatsby';
import React, { ReactNode, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import Header from '../../components/HeaderDynamicPages/Header';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import PrinterWrapper from '../../components/PrintWrapper/PrintWrapper';
import {
    InformationNote,
    InformationNoteText,
    InformationWrapper,
    SecondHandMachinesTitle,
} from '../../components/SecondHandMachinesPage/SecondHandMachinesPageStyle';
import {
    CardsContainer,
    CardImage,
    ContentWrapper,
    NavigationItem,
    NavigationWrapper,
    ProductTitle,
    SecondHandMachinesDetailsWrapper,
    TextContentWrapper,
    TextContentTitle,
    PropertyBox,
    PropertyKey,
    PropertyValue,
    AttachmentsContainer,
    AttachmentImage,
    PriceInfoWrapper,
    PropertyItemBox,
} from './secondHandMachinesDetailsStyle';
import { secondHandMachinesContentLoader } from '../../utils/secondHandMachinesContentLoader';
import { dataToBreadcrumbsFromNav } from '../../utils/dataToBreadCrumbsFromNav';
import { getSecondHandMachinesUrl } from '../../utils/getSecondHandMachinesUrl';
import { DE } from '../../constants/languageCode';

const secondHandMachinesDetails = ({ pageContext }) => {
    const { secondHandMachinesDetails } = pageContext;
    const { t } = useTranslation();
    const { language } = useI18next();
    const [activeTab, setActiveTab] = useState('description');
    const { pleaseNoteDetailText, priceInfo } = secondHandMachinesContentLoader(language);
    const secondHandMachineUrl = getSecondHandMachinesUrl(language);
    const { category, additionalItem } = dataToBreadcrumbsFromNav(language, secondHandMachineUrl, secondHandMachineUrl);
    const headerItemsName = ['description', 'technicalSpecifications', 'price'];
    const itemKey = 0;
    const itemValue = 1;
    const textContent = {
        description: {
            title:
                language === DE
                    ? secondHandMachinesDetails?.data?.values?.vZBVKAT
                    : secondHandMachinesDetails?.data?.values?.ZBVKAT_EN,
            data: [
                [t('builder'), secondHandMachinesDetails?.data?.values?.vZHersteller],
                [
                    t('description'),
                    language === DE && secondHandMachinesDetails?.data?.values?.vZBVTEXT?.length > 0
                        ? secondHandMachinesDetails?.data?.values?.vZBVTEXT?.replace(/–/g, '<span>&#10003;</span>')
                        : '',
                ],
            ],
        },
        technicalSpecifications: {
            data: [
                [t('year'), secondHandMachinesDetails?.data?.values?.vZBVJAHR],
                [t('pressure'), secondHandMachinesDetails?.data?.values?.vZBVDRUCK],
                [t('freeAirDelivery'), secondHandMachinesDetails?.data?.values?.vZBVMH],
                [t('motor'), secondHandMachinesDetails?.data?.values?.vZBVLEISTUNG],
                [t('voltage'), secondHandMachinesDetails?.data?.values?.vZElektrik],
                [t('measurementWhd'), secondHandMachinesDetails?.data?.values?.vZAbmessung],
                [t('compressedAirConnection'), secondHandMachinesDetails?.data?.values?.vZDruckluftanschluss],
                [t('workingHours'), secondHandMachinesDetails?.data?.values?.BetStd],
                [t('weight'), secondHandMachinesDetails?.data?.values?.vZGewicht],
                [
                    t('state'),
                    language === DE
                        ? secondHandMachinesDetails?.data?.values?.vZBVZS_1
                        : secondHandMachinesDetails?.data?.values?.vZBVZS_1_EN,
                ],
            ],
        },
        price: {
            data: [
                [
                    t('listPrice'),
                    secondHandMachinesDetails?.data?.values?.vZBVLP?.length > 0 &&
                        `${secondHandMachinesDetails?.data?.values?.vZBVLP} &#128; ${t('exclTax')}`,
                ],
                [
                    t('onlineShopPrice'),
                    secondHandMachinesDetails?.data?.values?.vZBVPREIS?.length > 0 &&
                        `${secondHandMachinesDetails?.data?.values?.vZBVPREIS} &#128; ${t('exclTax')}`,
                ],
                [t('guarantee'), secondHandMachinesDetails?.data?.values?.vZGarantie],
                [
                    t('rent'),
                    secondHandMachinesDetails?.data?.values?.vMietP?.length > 0 &&
                        `${secondHandMachinesDetails?.data?.values?.vMietP} &#128; ${t('exclTax')}`,
                ],
            ],
        },
    };

    const areAttachmentsIncluded = () =>
        !!(
            secondHandMachinesDetails?.data?.attachments?.length ||
            Object.keys(secondHandMachinesDetails?.data?.attachments).length
        );

    const displayNavigation = (): ReactNode => (
        <NavigationWrapper>
            {headerItemsName.map((item: string, index: number) => (
                <NavigationItem onClick={() => setActiveTab(item)} key={item + index} isActive={activeTab === item}>
                    {t(item)}
                </NavigationItem>
            ))}
            {areAttachmentsIncluded() && (
                <NavigationItem
                    onClick={() => setActiveTab('photosDownloads')}
                    isActive={activeTab === 'photosDownloads'}
                >
                    {t('photosDownloads')}
                </NavigationItem>
            )}
        </NavigationWrapper>
    );

    const displayAttachments = (): ReactNode => {
        const dataToDisplay = Object?.entries(secondHandMachinesDetails?.data?.attachments);
        return (
            <AttachmentsContainer>
                {dataToDisplay?.map((item: Array<any>) => (
                    <a href={item[itemValue]} key={item[itemKey]} target="_blank">
                        <AttachmentImage src={item[itemValue]} alt={item[itemKey]} />
                    </a>
                ))}
            </AttachmentsContainer>
        );
    };

    const displayTextContent = (data: Array<any>): ReactNode => (
        <PropertyBox>
            {data
                .filter((item) => item[itemValue] !== '')
                .map((item: any, index: number) => (
                    <PropertyItemBox>
                        <PropertyKey key={item[itemKey]}>{item[itemKey]}: </PropertyKey>
                        <PropertyValue
                            key={item[itemKey] + index}
                            dangerouslySetInnerHTML={{ __html: item[itemValue] }}
                        ></PropertyValue>
                    </PropertyItemBox>
                ))}
        </PropertyBox>
    );

    const displayCardImage = (): ReactNode => (
        <CardImage
            src={`https://support.boge.com/www/support/usedequip.nsf/0/${secondHandMachinesDetails?.unid}/Photo/0.84?OpenElement&FieldElemFormat=jpg`}
        />
    );

    const displayContentWrapper = (): ReactNode => (
        <ContentWrapper>
            <CardsContainer isVisible={activeTab === 'description'}>
                {displayCardImage()}
                <TextContentWrapper>
                    <TextContentTitle>{textContent.description.title}</TextContentTitle>
                    {displayTextContent(textContent.description.data)}
                </TextContentWrapper>
            </CardsContainer>
            <CardsContainer isVisible={activeTab === 'technicalSpecifications'}>
                {displayCardImage()}
                <TextContentWrapper>{displayTextContent(textContent.technicalSpecifications.data)}</TextContentWrapper>
            </CardsContainer>
            <CardsContainer isVisible={activeTab === 'price'}>
                {displayCardImage()}
                <TextContentWrapper>
                    {displayTextContent(textContent.price.data)}
                    {priceInfo !== null && (
                        <PriceInfoWrapper dangerouslySetInnerHTML={{ __html: priceInfo }}></PriceInfoWrapper>
                    )}
                </TextContentWrapper>
            </CardsContainer>
            <CardsContainer isVisible={activeTab === 'photosDownloads'}>
                {areAttachmentsIncluded() && displayAttachments()}
            </CardsContainer>
        </ContentWrapper>
    );

    return (
        <Layout title={secondHandMachinesDetails?.Series}>
            <Header category={category} subCategory={additionalItem} item={t('secondHandMachinesInDetails')} />
            <PrinterWrapper isShmPage={true} shouldHasGoBackArrow={true}>
                <SecondHandMachinesDetailsWrapper>
                    <SecondHandMachinesTitle>{t('secondHandMachinesInDetails')}</SecondHandMachinesTitle>
                    <ProductTitle>{secondHandMachinesDetails?.Series}</ProductTitle>
                    {displayNavigation()}
                    {displayContentWrapper()}
                    <InformationWrapper>
                        <InformationNote>{t('pleaseNote')}</InformationNote>
                        <InformationNoteText
                            isDetailPage={true}
                            dangerouslySetInnerHTML={{ __html: pleaseNoteDetailText }}
                        ></InformationNoteText>
                    </InformationWrapper>
                </SecondHandMachinesDetailsWrapper>
            </PrinterWrapper>
        </Layout>
    );
};

export default secondHandMachinesDetails;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
