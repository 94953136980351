import { DE, DE_AT, EN, EN_AU, EN_IN, EN_SG, EN_UK, ES, ES_ES, FR, FR_FR } from '../constants/languageCode';
import { linkHelper } from './linkHelper';

export const secondHandMachinesContentLoader = (language: string) => {
    switch (language) {
        case DE:
        case DE_AT:
            return {
                instructionText: `<p>Bitte bieten Sie uns Ihre nicht mehr benötigte Druckluftkomponente an. 
                Wir senden Ihnen ein Ankaufangebot, oder geben Ihnen Tipps für den Verkauf.
                Das Angebot senden Sie bitte per E-Mail an unseren Herrn Wenzel (<a href="mailto:t.wenzel@boge.de">t.wenzel@boge.de</a>).</p>`,
                instructionListElement: [
                    'Maschinendaten',
                    'Hersteller',
                    'Typ',
                    'Maschinennummer',
                    'Baujahr',
                    'Höchstdruck',
                    'Betriebsstunden',
                    'Zustand (optisch)',
                    'Zustand (Wartung)',
                    'Wer hat die Wartung durchgeführt',
                    'Ihre Preisvorstellung',
                    'Grund des Verkaufs',
                ],
                pleaseNoteText: `<p class="description-after-achtung">
                Bestellungen / Anmietungen nur telefonisch oder per Email: +49 5206 601-164 oder t.wenzel(at)boge.de.<br>
                Die Maschinenübersicht wird alle 30 Minuten automatisch aktualisiert! Zwischenverkauf vorbehalten.<br>
                Bereitstellung für Versand i.d.R. innerhalb eines Werktages nach Bestellung.<br>
                Beachten Sie bitte auch unsere<a href="${linkHelper(
                    '/agb',
                    language
                )}" target="_blank"> Geschäftsbedingungen</a> und unsere <a href="${linkHelper(
                    '/allgemeine-mietbedingungen',
                    language
                )}" target="_blank">Allgemeine Mietbedingungen</a>       </p>`,
                pleaseNoteDetailText: `<p>Bestellungen nur telefonisch oder per Email:<br>
                +49 5206 601-164 oder t.wenzel(at)boge.de.<br>
                Die Maschinenübersicht wird alle 30 Minuten automatisch aktualisiert!<br>
                Zwischenverkauf vorbehalten.<br>
                Bereitstellung für Versand i.d.R. innerhalb eines Werktages nach Bestellung. </p>`,
                priceInfo: `<span>Mietpreis pro angefangene Kalenderwoche. <br>Wird bei Mietbeginn eine Laufzeit von 4 Wochen und mehr vereinbart, reduziert sich der Mietpreis um 20%. Mindestmietpreis EURO 300,00 zzgl. MwSt. <br>Beachten Sie bitte auch unsere <a target="_blank" href="${linkHelper(
                    '/agb',
                    language
                )}">Geschäftsbedingungen</a> und unsere <a target="_blank" href="${linkHelper(
                    '/allgemeine-mietbedingungen',
                    language
                )}">Allgemeinen Mietbedingungen</a>.</span>`,
            };
        case EN:
        case EN_UK:
        case EN_AU:
        case EN_SG:
        case EN_IN:
            return {
                instructionText: `<p>Please offer us your unneeded pneumatic component.
                We will send you a purchase offer, or give you tips for selling.
                Please send the offer by e-mail to Mr. Wenzel (t.wenzel@boge.de).
                Besides images, we will need the following information:</p>`,
                instructionListElement: [
                    'Machine data',
                    'Producer',
                    'Type',
                    'Machine number',
                    'Year of production',
                    'Pressure',
                    'Operating hour',
                    'State (optical)',
                    'Condition (maintenance)',
                    'Who has performed the maintenance',
                    'Your asking price',
                    'Reason for the sale',
                ],
                pleaseNoteText: `<p class="description-after-achtung">
                For orders/rentals just call or send us an email: +49 5206 601-164 or t.wenzel(at)boge.de <br> 
                The machine overview is updated every 30 minutes automatically.! Subject to prior sale. <br> 
                Provision for shipping i.d.R. within one business day after ordering. <br>
                Please see our<a href="${linkHelper(
                    '/general-terms',
                    language
                )}" target="_blank"> Terms and Conditions</a> and our <a href="${linkHelper(
                    '/general-terms-of-rental',
                    language
                )}" target="_blank">General Rental Conditions.</a>       </p>`,
                pleaseNoteDetailText: `<p>Orders will only be accepted by telephone or by email:<br>
                Tel. +49 5206 601-164 / email t.wenzel(at)boge.de.<br>
                Automatic update of stock will be every 30 minutes.<br>
                Subject to being unsold.<br>
                These compressors will normally be ready for despatch within one working day after receipt or order.</p>`,
                priceInfo: null,
            };
        case ES:
        case ES_ES:
            return {
                instructionText: `<p>Ofrézcanos los componentes de aire comprimido que ya no necesite. Le enviaremos una oferta de compra o le daremos consejos para su venta. Envíe su oferta por correo electrónico (<a href="mailto:iberica@boge.com">iberica@boge.com</a>). <br> Además de fotografías, necesitamos los siguientes datos:</p>`,
                instructionListElement: [
                    'Datos de la máquina',
                    'Fabricante',
                    'Tipo',
                    'Número de máquina',
                    'Año de fabricación',
                    'Máxima presión',
                    'Horas de trabajo',
                    'Estado (visual)',
                    'Estado (mantenimiento)',
                    'Persona que ha realizado el mantenimiento',
                    'Precio que espera obtener',
                    'Motivo de la venta',
                ],
                pleaseNoteText: `<p class="description-after-achtung">
                Los pedidos se realizan exclusivamente por teléfono o por correo electrónico, en el número <a href="tel:+34 916573505">+34 916573505</a> o en la dirección <a href="mailto: iberica@boge.com">iberica@boge.com</a>.<br>
                La lista de máquinas se actualiza automáticamente cada 30 minutos.<br>
                Reservado el derecho de realizar ventas intermedias.<br>
                Por lo general, la preparación para el envío se realiza en el transcurso de un día laborable una vez emitido el pedido.<br>
                <a href="${linkHelper(
                    '/cgc-es',
                    language
                )}" target="_blank"> Condiciones comerciales</a>, <a href="${linkHelper(
                    '/condiciones-generales-alquiler',
                    language
                )}" target="_blank"> condiciones generales de alquiler.</a>       </p>`,
                pleaseNoteDetailText: `<p>Los pedidos se realizan exclusivamente por teléfono o por correo electrónico, en el número +34 916573505 o en la dirección iberica@boge.com. La lista de máquinas se actualiza automáticamente cada 30&nbsp;minutos. Reservado el derecho de realizar ventas intermedias. Por lo general, la preparación para el envío se realiza en el transcurso de un día laborable una vez emitido el pedido.</p>`,
                priceInfo: null,
            };
        case FR:
        case FR_FR:
            return {
                instructionText: `<p>Proposez-nous les composants pour l'air comprimé dont vous n'avez plus besoin. Nous vous enverrons une offre d'achat ou vous donnerons des conseils pour la vente. Veuillez envoyer votre offre par e-mail à M. Wenzel (<a href="mailto:t.wenzel@boge.de">t.wenzel@boge.de</a>). <br> Nous avons besoin de photos et des informations suivantes&nbsp;:</p>`,
                instructionListElement: [
                    'Données de la machine',
                    'Fabricant',
                    'Type',
                    'Numéro de la machine',
                    'Année de fabrication',
                    'Pression maximale',
                    'Heures de service',
                    'État (aspect)',
                    'État (maintenance)',
                    'Qui était chargé de la maintenance',
                    'Votre prix',
                    'Motif de la vente',
                ],
                pleaseNoteText: `<p class="description-after-achtung">
                Commandes par téléphone ou e-mail uniquement : <a href="tel:+495206601164">+49 5206 601-164</a> ou <a href="mailto:t.wenzel@boge.de"> t.wenzel@boge.de</a>.<br>
                L'aperçu des machines est mis à jour toutes les 30 minutes&nbsp;! Sauf vente entre-temps.<br>
                En règle générale, préparation à l’expédition dans un délai d'un jour ouvrable après la commande.<br>
                Consultez également nos conditions de vente et nos conditions générales de location.<br>
                <a href="${linkHelper(
                    '/conditions-generales-fr',
                    language
                )}" target="_blank"> Conditions générales de location</a>, <a href="${linkHelper(
                    '/conditions-de-vente',
                    language
                )}" target="_blank"> conditions de vente.</a>
               </p>`,
                pleaseNoteDetailText: `<p>Commandes par téléphone ou e-mail uniquement&nbsp;:<br> 
               +49 5206 601-164 ou t.wenzel(at)boge.de. 
               L'aperçu des machines est mis à jour toutes les 30 minutes&nbsp;! 
               Sauf vente entre-temps. 
               En règle générale, préparation à l’expédition dans un délai d'un jour ouvrable après la commande.</p>`,
                priceInfo: null,
            };
        default:
            return {
                instructionText: null,
                instructionListElement: [],
                pleaseNoteText: null,
                pleaseNoteDetailText: null,
                priceInfo: null,
            };
    }
};
