import {
    DE,
    DE_AT,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES_ES,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
    EN,
    FR,
    FR_BE,
    ES_US,
    ES,
} from '../constants/languageCode';

export const getSecondHandMachinesUrl = (language: string): string => {
    switch (language) {
        case DE:
            return 'gebraucht-mietmaschinen';
        case DE_AT:
            return 'gebraucht-mietmaschinen';
        case EN_AU:
            return 'second-hand-machines';
        case EN_IN:
            return 'second-hand-machines';
        case EN_SG:
            return 'second-hand-machines';
        case EN_UK:
            return 'second-hand-machines';
        case EN_US:
            return 'second-hand-machines';
        case ES_ES:
            return 'maquinas-usadas';
        case FR_FR:
            return 'machines-d-occasion';
        case IT:
            return 'second-hand-machines';
        case NL_NL:
            return 'second-hand-machines';
        case NL_BE:
            return 'second-hand-machines';
        case ZH_HANS:
            return 'second-hand-machines';
        case EN:
            return 'second-hand-machines';
        case FR:
            return 'machines-d-occasion';
        case FR_BE:
            return 'machines-d-occasion';
        case ES_US:
            return 'maquinas-usadas';
        case ES:
            return 'maquinas-usadas';
        default:
            return 'presseinformationen';
    }
};
